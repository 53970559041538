import store from '@/store';
import { CHANGE_LANG } from '@/store/actions.type';

export default async (to, from, next) => {
	const { langs } = store.getters;
	const lang = to.query?.lang
	if (langs && lang) {
		if (Array.isArray(langs)) {
			if (langs.includes(lang)) {
				await store.dispatch(CHANGE_LANG, lang);
			}
		  }
	}
    next();
};
