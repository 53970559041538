import events from '@/events';
import guards from '@/router/guards';
import Router from '@/router/router';
import routes from '@/router/routes';
import Vue from 'vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes,
});

// Before guards.
router.beforeEach(guards.bootstrap);
router.beforeEach(guards.redirect);
router.beforeEach(guards.authentication);
router.beforeEach(guards.authorization);
router.beforeEach(guards.api);
router.beforeEach(guards.lang);

// After guards.
router.afterEach(guards.props);

// Events.
events.$on('router:push', (location) => router.push(location));
events.$on('router:replace', (location) => router.replace(location));
events.$on('router:go', (n) => router.go(n));

export default router;
